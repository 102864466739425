import { Grid } from '@mui/material';
import React, { useEffect } from 'react'
import Logo from '../asserts/logo.svg'
import { useNavigate } from "react-router-dom";


function TermsandConditions() {
    const navigate = useNavigate()
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
  return (
    <Grid lg={12} container>
        <Grid lg={12} container justifyContent="center" className='header_main'>
            <Grid lg={10} md={10} container justifyContent="space-between" alignItems="center" className='header_contents'>
                <Grid lg={1.5} md={2} sm={3} xs={4} container className='my-2'>
                    <img onClick={() =>{navigate("/")}} className='main_logo' src={Logo} alt=''/>
                </Grid>
                <Grid lg={1.5} md={2} sm={2} xs={4} container className='my-2 justify-content-end'>
                    <button onClick={() =>{navigate("/")}} className='login_btn'>Home</button>
                </Grid>
            </Grid>
        </Grid>
        <div className="container-fluid">
            <div className="row justify-content-center">
            <div className="col-12 main_content_terms">
                <div className="row justify-content-center">
                    <div className="col-12 text-center my-5">
                        <h1>Terms & Conditions</h1>
                    </div>
                    <div className="col-lg-10 col-11">
                        <div className="row">
                            <h5>Terms and Conditions for LOOV - A Field Force Manager</h5>
                            <p>Welcome to LOOV, a product owned by MS IT PARK PVT LTD. By accessing or using LOOV, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the service.</p>
                            <div className="col-12">
                                <div className="row">
                                    <h5>Usage of Our Service:</h5>
                                    <ul>
                                        <li>
                                            <p>LOOV provides a field force management solution including features like location tracking, expense management, and more.</p>
                                        </li>
                                        <li>
                                            <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <h5>Payment and Renewal:</h5>
                                    <ul>
                                        <li><p>Services are billed on a subscription basis. You will be billed in advance on a recurring, periodic basis (e.g., monthly or annually).</p></li>
                                        <li><p>We do not offer refunds for any subscriptions. It is your responsibility to evaluate LOOV’s suitability for your needs prior to subscribing.</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <h5>Intellectual Property:</h5>
                                    <ul>
                                        <li><p>The service and its original content, features, and functionality are and will remain the exclusive property of MS IT PARK PVT LTD and its licensors.</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <h5>User Conduct:</h5>
                                    <ul>
                                        <li><p>You agree not to use the service in any way that is unlawful, illegal, or harmful to others.</p></li>
                                        <li><p>You agree not to engage in any activity that disrupts or interferes with the service.</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <h5>Termination:</h5>
                                    <ul>
                                        <li><p>We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <h5>Limitation of Liability:</h5>
                                    <ul>
                                        <li><p>In no event shall MS IT PARK PVT LTD be liable for any indirect, incidental, special, consequential or punitive damages arising out of or in connection with your use of the service.</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <h5>Changes to Terms:</h5>
                                    <ul>
                                        <li><p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any significant changes.</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <h5>Contact Us:</h5>
                                    <ul>
                                        <li><p>If you have any questions about these Terms, please contact us.</p></li>
                                    </ul>
                                    <p>By using LOOV, you agree to these Terms and Conditions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            </div>
        </div>
    </Grid>
  )
}

export default TermsandConditions
