import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import Logo from '../asserts/logo.svg'
import { useNavigate } from "react-router-dom";
function PrivacyPolicy() {
    const navigate = useNavigate()
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
  return (
    <Grid lg={12} container>
        <Grid lg={12} container justifyContent="center" className='header_main'>
            <Grid lg={10} md={10} container justifyContent="space-between" alignItems="center" className='header_contents'>
                <Grid lg={1.5} md={2} sm={3} xs={4} container className='my-2'>
                    <img onClick={() =>{navigate("/")}} className='main_logo' src={Logo} alt=''/>
                </Grid>
                <Grid lg={1.5} md={2} sm={2} xs={4} container className='my-2 justify-content-end'>
                    <button onClick={() =>{navigate("/")}} className='login_btn'>Home</button>
                </Grid>
            </Grid>
        </Grid>
    <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 main_content_terms">
            <div className="row justify-content-center">
                <div className="col-12 text-center my-5">
                    <h1>Privacy Policy</h1>
                  </div>
                  <div className="col-lg-10 col-11">
                    <div className="row">
                        <div className="col-12 inner_spliter">
                            <div className="row">
                                <h5>Privacy Policy for LOOV - A Field Force Manager</h5>
                                <p>This Privacy Policy outlines how MS IT PARK PVT LTD ("we", "our", or "us") collects, uses, and shares information through LOOV - A Field Force Manager ("LOOV", "the app"). By using LOOV, you agree to the collection and use of information in accordance with this policy.</p>
                            </div>
                        </div>     
                        <div className="col-12 inner_spliter">
                            <div className="row">
                                <h5>Information We Collect:</h5>
                                <p><b>Location Data:</b> LOOV collects real-time background location data to provide live location tracking of employees, a core feature of the app. This data helps owners and managers monitor where employees are working.</p>
                                <p><b>Camera Access:</b> LOOV accesses the camera on your device to allow users to take photos as proof of work or task completion. These images are uploaded to the app for verification purposes.</p>
                                <p><b>Personal and Business Information:</b> We collect information necessary for the operation of LOOV, including but not limited to names, addresses, and business operational data.</p>
                            </div>
                        </div>      
                        <div className="col-12 inner_spliter">
                            <div className="row">
                                <h5>How We Use Your Information:</h5>
                                <ul>
                                    <li><p>To provide and improve our services, including live location tracking and task management.</p></li>
                                    <li><p>To support business growth and development, including analytics and performance monitoring.</p></li>
                                </ul>
                                
                            </div>
                        </div>      
                        <div className="col-12 inner_spliter">
                            <div className="row">
                                <h5>Sharing Your Information:</h5>
                                <ul>
                                    <li><p>We may share your information with third parties if it's deemed necessary for client growth or as required by law.</p></li>
                                    <li><p>We ensure that data shared is protected under confidentiality agreements and is used in compliance with this privacy policy.</p></li>
                                </ul>
                            </div>
                        </div>      
                        <div className="col-12 inner_spliter">
                            <div className="row">
                                <h5>Data Security:</h5>
                                <ul>
                                    <li><p>We implement measures to secure your data against unauthorized access, alteration, disclosure, or destruction.</p></li>
                                </ul>
                            </div>
                        </div>      
                        <div className="col-12 inner_spliter">
                            <div className="row">
                                <h5>Changes to This Privacy Policy:</h5>
                                <ul>
                                    <li><p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "effective date" at the top.</p></li>
                                </ul>
                            </div>
                        </div>      
                        <div className="col-12 inner_spliter">
                            <div className="row">
                                <h5>Contact Us:</h5>
                                <ul>
                                    <li><p>If you have any questions about this Privacy Policy, please contact us.</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    </Grid>
  )
}

export default PrivacyPolicy
